// 账本基本字段
let tableData = [
  // {
  //   label: '序号',
  //   type: 'index',
  //   align: 'center',
  //   show: true,
  //   width: 70
  // },
  {
    label: '任务ID',
    prop: 'ledgerTaskOverviewID',
    show: true,
    minWidth: 185
  },
  {
    label: '分片名',
    prop: 'shard',
    show: true,
    minWidth: 120
  },
  {
    label: '总任务进度',
    prop: 'undoTask',
    show: true,
    enableSlot: true,
    minWidth: 185
  },
  {
    label: '状态',
    prop: 'status',
    show: true,
    enableSlot: true,
    minWidth: 190,
    maxWidth: 400
  },
  {
    label: '创建时间',
    prop: 'createTime',
    show: true,
    enableSlot: true,
    minWidth: 190
  },
  {
    label: '结束时间',
    prop: 'endTime',
    show: true,
    enableSlot: true,
    minWidth: 190
  },
  {
    prop: 'operators',
    label: '操作',
    fixed: 'right',
    align: 'right',
    width: 100,
    children: [
      {
        icon: 'el-icon-view',
        label: '查看',
        clickEvent: (thisVue, row) => {
          thisVue.handleDetail(row.ledgerTaskOverviewID)
        },
        showFilter: () => {
          return true
        }
      }
    ]
  }
]

let headData = {
  title: '账本任务列表'
}

let tableObj = {
  'tableData': tableData,
  'headData': headData
}

export default tableObj
