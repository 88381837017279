<template>
  <div>
    <table-list :columns="columns"
                :data="list"
                fit
                stripe
                :tableOption="{size: 'mini'}"
                :loading="loading"
                :pageData="pageData"
                :headData="headData"
                @handlePageChange="handlePageChange"
                @handleSizeChange="handleSizeChange">
      <!-- 创建时间 -->
      <template #createTime="{row}">
        {{ tf(row.createTime, 'YYYY-MM-DD HH:mm:ss') }}
      </template>

      <!-- 结束时间 -->
      <template #endTime="{row}">
        <span v-if="row.endTime">{{ tf(row.endTime, 'YYYY-MM-DD HH:mm:ss') }}</span>
        <span v-else>无</span>
      </template>

      <!-- 执行状态 -->
      <template #status="{row}">
        <span v-if="row.status === 0">未执行</span>
        <span v-else-if="row.status === 1">执行中</span>
        <span v-else-if="row.status === 2">成功</span>
        <span v-else-if="row.status === 3">失败</span>
        <popover v-else-if="row.status === 4"
                 :width="500"
                 :shard="row.shardName"
                 :value="row.remark"
                 :isSubStr="false"
                 :showDict="false"
                 :divClass="'shenglue-1'"></popover>
      </template>

      <!-- 总任务进度 -->
      <template #undoTask="{row}">
        <el-progress :text-inside="true"
                     :stroke-width="15"
                     :percentage="percentage(row)"
                     :color="customColorMethod"></el-progress>
      </template>

    </table-list>
  </div>
</template>

<script>
import TableList from '@/components/TableList'
import Popover from '@/components/Popover'
import timeFormat from '@/utils/timeFormat.js'
import tableObj from '../component/tableData.js'
export default {
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    datas: {
      handler() {
        console.log('监听:', this.datas)
        this.pageData.currentPage = 1
        this.getData(1)
        // this.refresh(1)
      },
      deep: false,
      immediate: true
    }
  },
  components: {
    TableList,
    Popover
  },
  data() {
    return {
      loading: false,
      list: [], // 列表
      body: {},
      pageData: {
        size: 10, // 每页的数据量
        pageSize: 10, // 每页的条数跳转页面
        pageSizes: [10, 20, 50, 100], // 每页的条数跳转页面
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, sizes, jumper, slot',
        currentPage: 1 // 当前页
      }, // 分页配置
      columns: tableObj.tableData,
      headData: tableObj.headData,
      timer: '' // 定时器
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {
    if (this.timer) {
      window.clearInterval(this.timer)
      this.timer = ''
    }
  },
  methods: {
    // 进度条动态颜色
    customColorMethod(percentage) {
      if (percentage < 20) {
        return '#909399'
      } else if (percentage < 50) {
        return '#f56c6c'
      } else if (percentage < 80) {
        return '#e6a23c'
      } else {
        return '#5cb87a'
      }
    },
    // 计算任务进度
    percentage(row) {
      if (row.totalTask !== 0) {
        return Math.round((1 - row.undoTask / row.totalTask) * 100)
      } else {
        return 0
      }
    },
    // 定时刷新数据
    refresh(currentPage) {
      var _this = this
      window.clearInterval(this.timer)
      this.timer = window.setInterval(function () {
        _this.getData(currentPage)
      }, 5000)
    },
    // 跳转详情页
    handleDetail(value) {
      this.$router.push({
        name: 'ledgerTaskDetails',
        query: { overviewID: value }
      })
    },
    // 时间转换
    tf(time, format) {
      return timeFormat(time, format)
    },
    dataBody() {
      var datas = this.datas
      let body = {
        ledgerTaskOverviewID: datas.ID || null,
        shard: datas.shard,
        status: datas.status,
        createTimeStart: Date.parse(datas.startList[0]) || null,
        createTimeEnd: Date.parse(datas.startList[1]) || null,
        endTimeStart: Date.parse(datas.endList[0]) || null,
        endTimeEnd: Date.parse(datas.endList[1]) || null
      }
      return body
    },
    async getData(currentPage) {
      this.loading = true
      let option = {
        page: currentPage - 1,
        size: this.pageData.size
      }
      let { data, code } = await this.$api.middleWare.overviewList(
        option,
        this.dataBody()
      )
      if (code === 200) {
        // console.log('数据1:', data.content)
        this.pageData.total = data.total
        this.list = data.content
      }
      this.loading = false
    },
    handlePageChange(goPage) {
      this.pageData.currentPage = goPage
      this.getData(goPage)
      // this.refresh(goPage)
    },
    // 配置每页条数
    handleSizeChange(number) {
      this.pageData.currentPage = 1
      this.pageData.size = number
      this.pageData.pageSize = number
      this.getData(1)
      // this.refresh(1)
    }
  }
}
</script>

<style lang="scss" scoped>
.table-row:hover {
  text-decoration: underline;
  color: $--color-primary;
}
// 不换行省略
.shenglue {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
// js样式
/deep/ pre {
  background: rgb(230, 230, 230);
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  overflow-x: scroll;
  .string {
    color: green;
  }
  .number {
    color: darkorange;
  }
  .boolean {
    color: blue;
  }
  .null {
    color: magenta;
  }
  .key {
    color: red;
  }
}
/deep/ .el-dialog__body {
  padding: 0px 20px 20px 20px !important;
}
</style>
