<template>
  <div class="ym-page">
    <!-- 顶部组件 -->
    <search-form :form="form" @search="search" @select="select"></search-form>
    <!-- 任务列表 -->
    <table-list :datas="searchVal"></table-list>
  </div>
</template>

<script>
import SearchForm from './component/SearchForm.vue'
import TableList from './component/TableList.vue'
import { getShardName, setShardName } from '@/utils/localStorage.js'
export default {
  components: {
    SearchForm,
    TableList
  },
  data() {
    return {
      // 表单
      form: {
        ID: '', // 操作ID
        shard: null, // 分片名（默认全部）
        status: null, // 执行状态（默认全部）
        startList: [], // 创建时间范围
        endList: [] // 结束时间范围
      },
      searchVal: {
        ID: '', // 操作ID
        shard: null, // 分片名（默认全部）
        status: null, // 执行状态（默认全部）
        startList: [], // 创建时间范围
        endList: [] // 结束时间范围
      }
    }
  },
  created() {
    if (getShardName()) {
      this.form.shard = getShardName()
      this.searchVal.shard = getShardName()
    }
  },
  mounted() {
    // 新手指引
    this.$nextTick(() => {
      this.$publicFunction.myIntroJs(this.$route.name)
    })
  },
  methods: {
    // 分片名切换刷新
    select(value) {
      setShardName(value)
    },
    search(form) {
      // 重置表单
      this.form = form
      // 搜索列表
      this.searchVal = form
      // console.log('清空表单:', form)
    }
  }
}
</script>
