import { render, staticRenderFns } from "./TableList.vue?vue&type=template&id=5876d406&scoped=true&"
import script from "./TableList.vue?vue&type=script&lang=js&"
export * from "./TableList.vue?vue&type=script&lang=js&"
import style0 from "./TableList.vue?vue&type=style&index=0&id=5876d406&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5876d406",
  null
  
)

export default component.exports