var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table-list',{attrs:{"columns":_vm.columns,"data":_vm.list,"fit":"","stripe":"","tableOption":{size: 'mini'},"loading":_vm.loading,"pageData":_vm.pageData,"headData":_vm.headData},on:{"handlePageChange":_vm.handlePageChange,"handleSizeChange":_vm.handleSizeChange},scopedSlots:_vm._u([{key:"createTime",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.tf(row.createTime, 'YYYY-MM-DD HH:mm:ss'))+" ")]}},{key:"endTime",fn:function(ref){
var row = ref.row;
return [(row.endTime)?_c('span',[_vm._v(_vm._s(_vm.tf(row.endTime, 'YYYY-MM-DD HH:mm:ss')))]):_c('span',[_vm._v("无")])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.status === 0)?_c('span',[_vm._v("未执行")]):(row.status === 1)?_c('span',[_vm._v("执行中")]):(row.status === 2)?_c('span',[_vm._v("成功")]):(row.status === 3)?_c('span',[_vm._v("失败")]):(row.status === 4)?_c('popover',{attrs:{"width":500,"shard":row.shardName,"value":row.remark,"isSubStr":false,"showDict":false,"divClass":'shenglue-1'}}):_vm._e()]}},{key:"undoTask",fn:function(ref){
var row = ref.row;
return [_c('el-progress',{attrs:{"text-inside":true,"stroke-width":15,"percentage":_vm.percentage(row),"color":_vm.customColorMethod}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }